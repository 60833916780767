<template>
  <div class="order-list">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :success-duration="1300"
    >
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>加载中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        error-text="请求失败，点击重新加载"
        @load="onNext"
        class="pt10"
      >
        <OrderItem
          v-for="item in list"
          :key="item.id"
          :orderItem="item"
          :member_id="member_id"
          :customer_id="customer_id"
          :chat_id="chat_id"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import moment from 'moment'
// import pagination from './pagination.js'
import { pagination } from './pagination'
import { List, PullRefresh, Icon } from 'vant'
import { getShareOrderList } from '../../service/checkQr.service'
import { getCurExternalContact } from '@/utils/nwechat.js'
import OrderItem from './components/OrderItem.vue'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    OrderItem
  },
  props: {
    customer_id: {
      type: String,
      default: ''
    },
    chat_id: {
      type: String,
      default: ''
    }
  },
  setup() {
    const {
      list,
      params,
      loading,
      finished,
      refreshing,
      member_id,
      error,
      page,
      onRefresh,
      onNext
    } = pagination(getShareOrderList, { pageMode: true })

    // 刷新条时间
    const nowDate = () => {
      return moment(new Date()).format('HH:mm:ss')
    }

    return {
      params,
      list,
      loading,
      finished,
      refreshing,
      member_id,
      error,
      page,
      nowDate,
      onRefresh,
      onNext
    }
  },
  // data() {
  //   return {
  //     requestStatus: null,
  //     pollingIndex: 0,
  //     customer_id: ''
  //   }
  // },
  created() {
    // this.onRefresh()
    this.init()
  },

  methods: {
    init() {
      // 轮询请求id
      this.params.customer_userid = this.customer_id
      // this.page = 0
      this.onNext()
      // this.onRefresh()
      // this.requestStatus = setInterval(() => {
      //   if (this.customer_id || this.pollingIndex > 3) {
      //     // 赋值给参数
      //     this.params.customer_userid = this.customer_id
      //     this.onRefresh()
      //     clearInterval(this.requestStatus)
      //     return
      //   }
      //   console.log('现在是第', this.pollingIndex)
      //   this.pollingIndex++
      //   getCurExternalContact()
      //     .then((res) => {
      //       this.customer_id = res
      //     })
      //     .catch(() => {
      //       this.customer_id = ''
      //     })
      // }, 300)
    }
  }
}
</script>
