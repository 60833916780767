<template>
  <div class="goods-page">
    <van-sticky :offset-top="50">
      <div class="imitate-search-box mb10">
        <div class="imitate-input" @click="onSearch">
          <van-icon
            :name="require('@/assets/img/common/search.svg')"
            color="#999"
            size="16"
          />
          <span class="imitate-placehodor">搜索</span>
        </div>
        <!-- <div class="setting-icon">
          <van-icon
            :name="require('@/assets/img/quickSend/setting.svg')"
            size="20"
            @click="openSetting"
          />
        </div> -->
      </div>
    </van-sticky>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :offset="100"
      :success-duration="1300"
    >
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>加载中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        error-text="请求失败，点击重新加载"
        @load="onNext"
      >
        <GoodsItem
          v-for="item in list"
          :goodsItem="item"
          :key="item.id"
          :customer_id="customer_id"
          :chat_id="chat_id"
          :member_id="member_id"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { ref } from 'vue'
import moment from 'moment'
// import pagination from './pagination.js'
import { pagination } from './pagination'
import GoodsItem from './components/GoodsItem.vue'
import { List, PullRefresh, Icon, Sticky } from 'vant'
import { getShareGoodsList } from '../../service/checkQr.service'

export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    GoodsItem
  },
  props: {
    customer_id: {
      type: String,
      default: ''
    },
    chat_id: {
      type: String,
      default: ''
    }
  },
  setup() {
    const {
      list,
      params,
      loading,
      finished,
      member_id,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getShareGoodsList, { pageMode: true })

    // 刷新条时间
    const nowDate = () => {
      return moment(new Date()).format('HH:mm:ss')
    }

    return {
      params,
      list,
      loading,
      finished,
      refreshing,
      member_id,
      error,
      nowDate,
      onRefresh,
      onNext
    }
  },

  created() {
    this.onRefresh()
  },
  methods: {
    async onSearch() {
      this.$router.push('/goodsSearch')
    }
  }
}
</script>

<style lang="less">
@import url('~@/assets/style/imitate_search.less');
.imitate-search-box {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
